import styled, { css } from "styled-components";

import { COLOR, TEXT_COLOR } from "@sellernote/shared/src/sds-v2/styles/colors";
import { setFontStyle } from "@sellernote/shared/src/sds-v2/styles/typography";

const selectLanguage = styled.div<{
  isOpen: boolean;
  needsWhiteText: boolean | undefined;
}>`
  position: relative;
  padding-right: 4px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  color: ${({ needsWhiteText }) =>
    needsWhiteText ? TEXT_COLOR.white_1 : COLOR.grayScale_800};

  ${({ isOpen }) =>
    isOpen &&
    css`
      color: ${COLOR.primary_600};
    `}

  &:hover {
    color: ${COLOR.primary_800};
  }
`;

const iconAndLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  .language-label {
    ${setFontStyle("SubHead3")};
  }
`;

const languageList = styled.div<{ isMyPage: boolean }>`
  position: absolute;
  width: 100px;
  top: ${({ isMyPage }) => (isMyPage ? "42px" : "45px")};
  left: 0;
  background-color: ${COLOR.wh};
  box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15),
    0px 1px 3px 0px rgba(0, 0, 0, 0.3);

  .language-item {
    padding: 10px 10px 10px 16px;
    cursor: pointer;
    color: ${TEXT_COLOR.black_2};
    ${setFontStyle("Body2")};
  }
`;

export default {
  selectLanguage,
  iconAndLabel,
  languageList,
};
