import { ShipdaCurrentLanguage } from "@sellernote/shared/src/i18n/i18nForShipda";
import { convertContactNumberForEN } from "@sellernote/shared/src/utils/common/etc";

import ManagerList from "../components/ManagerList";

import { ShipdaService } from "../../..";
import MANAGER_RECORD from "../constants/MANAGER_RECORD";
import Styled from "./index.styles";

export default function Management({
  serviceType,
}: {
  serviceType: ShipdaService;
}) {
  return (
    <Styled.managementManager>
      <ManagerList
        managerList={
          ShipdaCurrentLanguage.currentLanguage === "en"
            ? MANAGER_RECORD[serviceType]["management"].map((manager) => ({
                ...manager,
                phone: convertContactNumberForEN(manager.phone),
              }))
            : MANAGER_RECORD[serviceType]["management"]
        }
      />
    </Styled.managementManager>
  );
}
