import Slider, { CustomArrowProps } from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ContactList from "@sellernote/shared/src/sds-v2/components/ContactList";
import ChevronLeftIcon from "@sellernote/shared/src/sds-v2/components/svgIcons/FourChevronLeftIcon";
import ChevronRightIcon from "@sellernote/shared/src/sds-v2/components/svgIcons/FourChevronRightIcon";
import { COLOR } from "@sellernote/shared/src/sds-v2/styles/colors";

import { ManagerInfoProps } from "../../../../..";
import Styled from "./index.styles";

const SLIDER_SETTING = (() => {
  {
    const PrevArrow = (props?: CustomArrowProps) => {
      if (!props) return null;

      const { className, onClick, currentSlide } = props;

      const isFirstSlide = currentSlide === 0;

      if (isFirstSlide) return null;

      return (
        <div
          className={className}
          style={{ display: "block" }}
          onClick={onClick}
        >
          <ChevronLeftIcon color={COLOR.grayScale_600} width={16} height={16} />
        </div>
      );
    };

    const NextArrow = (props?: CustomArrowProps) => {
      if (!props) return null;

      const { className, onClick, currentSlide, slideCount = 1 } = props;

      const isLastSlide = currentSlide === slideCount - 1;

      if (isLastSlide) return null;

      return (
        <div
          className={className}
          style={{ display: "block" }}
          onClick={onClick}
        >
          <ChevronRightIcon
            color={COLOR.grayScale_600}
            width={16}
            height={16}
          />
        </div>
      );
    };

    return {
      dots: false,
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      easing: "ease-in",
      rows: 4,
      prevArrow: <PrevArrow />,
      nextArrow: <NextArrow />,
    };
  }
})();

export default function ManagerListForDesktop({
  managerList,
}: {
  managerList: ManagerInfoProps[];
}) {
  return (
    <Styled.container>
      <Slider {...SLIDER_SETTING}>
        {managerList?.map(({ name, phone, email }) => (
          <ContactList key={email} name={name} phone={phone} email={email} />
        ))}
      </Slider>
    </Styled.container>
  );
}
