import { Trans } from "react-i18next";

import { ResponseFailureInfo } from "@sellernote/shared/src/types/common/common";

/**
 * 사용자의 로그인 실패 메시지를 생성하는 함수입니다.
 *
 * @param failureInfo - 로그인 실패 정보 객체입니다. 선택적 인수입니다.
 * @returns 로그인 실패에 대한 메시지를 반환합니다. 실패 정보가 없으면 빈 문자열을 반환합니다.
 *
 * @remarks
 * 이 함수는 로그인 실패 원인에 따라 다른 메시지를 반환합니다. i18nKey를 사용하여 다국어 지원을 합니다.
 */
export function makeUserLoginFailedMessage({
  failureInfo,
}: {
  failureInfo?: ResponseFailureInfo;
}) {
  if (!failureInfo) return "";

  const { code, error } = failureInfo;
  switch (code) {
    case 400: {
      if (error === "Can not login with forwarder id") {
        return <Trans i18nKey="auth-new:API_로그인_로그인_물류사계정_에러" />;
      } else {
        return <Trans i18nKey="auth-new:API_로그인_로그인_입력오류_에러" />;
      }
    }

    case 404: {
      return (
        <Trans i18nKey="auth-new:API_로그인_로그인_가입되지않은_아이디_에러" />
      );
    }

    default: {
      return <Trans i18nKey="auth-new:API_로그인_로그인_서버_에러" />;
    }
  }
}
