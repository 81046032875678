import styled, { css } from "styled-components";

import { mobile } from "@sellernote/shared/src/styles/functions";

const container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${mobile(css`
    margin-top: 8px;
  `)}
`;

const mobileTabContainer = styled.div`
  margin-top: 24px;

  .tab {
    margin-left: -8px;
  }

  ${mobile(css`
    .tab {
      > div {
        padding: 0px;
      }
    }

    align-self: start;
  `)}
`;

const manager = styled.div`
  margin-top: 16px;
  width: 100%;

  ${mobile(css`
    margin-top: 24px;
  `)}
`;

export default {
  container,
  mobileTabContainer,
  manager,
};
