import styled, { css } from "styled-components";

import { mobile } from "@sellernote/shared/src/styles/functions";

import { LogoType } from ".";

const logoContainer = styled.div<{ type: LogoType }>`
  > a {
    position: relative;
    display: inline-block;
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;

    > h1 {
      position: absolute;
      left: -9000px;
    }
  }

  ${({ type }) => {
    if (type === "header")
      return css`
        > a.logo {
          background-image: url("/assets/images/layout/logo.webp");
          width: 88px;
          height: 36px;

          ${mobile(css`
            width: 58.67px;
            height: 24px;
          `)}
        }
      `;

    return css`
      > a.logo {
        background-image: url("/assets/images/shipda_logo_white.png");
        width: 59px;
        height: 24px;
      }
    `;
  }}
`;

export default {
  logoContainer,
};
