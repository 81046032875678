import Link from "next/link";

import { GtmEvent } from "@sellernote/shared/src/types/common/common";
import { addEventToGTMDataLayer } from "@sellernote/shared/src/utils/common/gtm";
import Badge from "@sellernote/shared/src/sds-v2/components/Badge";
import { COLOR, TEXT_COLOR } from "@sellernote/shared/src/sds-v2/styles/colors";

import Styled from "./index.styles";

function SubmenuItem({
  label,
  badges,
  path,
  desc,
  Icon,
  gtmEvent,
}: {
  label: string;
  badges?: string[];
  path: string;
  desc: string;
  Icon: React.ReactNode;
  gtmEvent?: GtmEvent;
}) {
  const isExternalLink = path.includes("http");

  const handleSubMenuSelect = () => {
    if (!gtmEvent) {
      return;
    }

    addEventToGTMDataLayer(gtmEvent);
  };

  return (
    <Styled.submenuItem>
      <Link href={path}>
        <a
          target={isExternalLink ? "_blank" : "_top"}
          onClick={handleSubMenuSelect}
        >
          <Styled.iconBlock>{Icon}</Styled.iconBlock>

          <Styled.contentsBlock>
            <Styled.labelAndBadges>
              <span className="label">{label}</span>

              {badges && (
                <Styled.badges>
                  {badges.map((badge) => (
                    <Badge
                      key={badge}
                      borderType="filled"
                      colorInfo={{
                        background: COLOR.grayScale_600,
                        text: TEXT_COLOR.white_1,
                      }}
                      label={badge}
                      className="custom-badge"
                    />
                  ))}
                </Styled.badges>
              )}
            </Styled.labelAndBadges>

            <span className="desc">{desc}</span>
          </Styled.contentsBlock>
        </a>
      </Link>
    </Styled.submenuItem>
  );
}

export default SubmenuItem;
