import React, { useState } from "react";

import { useCheckIsMobile } from "@sellernote/shared/src/utils/common/hook";
import Tab from "@sellernote/shared/src/sds-v2/components/tab/Tab";

import ManagerSection from "./ManagerSection";
import MOBILE_TAB_LIST from "./MOBILE_TAB_LIST";
import Styled from "./index.styles";

export type ShipdaService = "forwarding" | "fulfillment";

export type ManagerType = "all" | "newcomerGuide" | "management";

export type ManagerInfoProps = {
  name: React.ReactNode;
  phone: string;
  email: string;
};

export type MobileTabData = {
  tabValue: ManagerType;
  title: React.ReactNode;
};

export default function ManagerInfoModalBody() {
  const { isMobile } = useCheckIsMobile();

  const [currentTab, setCurrentTab] = useState<ShipdaService>("forwarding");
  const [currentManagerTypeForMobile, setCurrentManagerTypeForMobile] =
    useState<ManagerType>("all");

  const handleTabChange = (service: ShipdaService) => setCurrentTab(service);

  return (
    <>
      <Styled.container>
        {isMobile && (
          <Styled.mobileTabContainer>
            <Tab
              direction="horizontal"
              selectedTab={currentManagerTypeForMobile}
              uiType="radioCheck"
              onSelect={(type: ManagerType) =>
                setCurrentManagerTypeForMobile(type)
              }
              tabDataList={MOBILE_TAB_LIST}
            />
          </Styled.mobileTabContainer>
        )}

        <Styled.manager>
          {currentTab === "forwarding" && (
            <ManagerSection
              serviceType="forwarding"
              currentManagerTypeForMobile={currentManagerTypeForMobile}
            />
          )}

          {currentTab === "fulfillment" && (
            <ManagerSection
              serviceType="fulfillment"
              currentManagerTypeForMobile={currentManagerTypeForMobile}
            />
          )}
        </Styled.manager>
      </Styled.container>
    </>
  );
}
