import { useMemo } from "react";

import { ButtonPropsV2 } from "@sellernote/shared/src/headlessComponents/button/useButton";

import InteractionStyleWrapper, {
  InteractionTheme,
} from "../../../styles/interactionStyle";

import Styled from "./index.styles";

export type ButtonTheme = InteractionTheme;

/**
 * 외부링크용 버튼. 버튼 UI 를 가진 a, text 요소.
 * 내부 페이지 이동도 추후 개선 필요. (Link 태그로 감싸거나 target, rel 처리)
 * */
export default function LinkButton({
  theme,
  borderType,
  size,
  width, // px, rem, % 관계없이 단위 포함해서 입력.
  label,
  href,
  disabled,
  iconInfo,
  onMouseEnter,
  onMouseLeave,
  onMouseDown,
  onMouseUp,
  className,
}: Omit<ButtonPropsV2, "handleClick"> & { href: string }) {
  const iconSize: number = useMemo(() => {
    if (size === "small") {
      return 12;
    }

    return 16;
  }, [size]);

  return (
    <Styled.container
      size={size}
      width={width}
      className={`${className ? className : ""} link-button`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
    >
      <InteractionStyleWrapper theme={theme} borderType={borderType}>
        <Styled.linkButton
          borderType={borderType}
          disabled={disabled}
          size={size}
          href={href}
          iconInfo={iconInfo}
          target="_blank"
          rel="noopener noreferrer"
        >
          {iconInfo?.position === "left" && iconInfo.Icon && (
            <iconInfo.Icon
              width={iconSize}
              height={iconSize}
              className="icon"
            />
          )}

          <span className="label">{label}</span>

          {iconInfo?.position === "right" && iconInfo.Icon && (
            <iconInfo.Icon
              width={iconSize}
              height={iconSize}
              className="icon"
            />
          )}
        </Styled.linkButton>
      </InteractionStyleWrapper>
    </Styled.container>
  );
}
