import styled, { css } from "styled-components";

import { mobile } from "@sellernote/shared/src/styles/functions";
import { COLOR, TEXT_COLOR } from "@sellernote/shared/src/sds-v2/styles/colors";
import { setElevationStyle } from "@sellernote/shared/src/sds-v2/styles/elevation";

const container = styled.header<{ isTransparentBackground?: boolean }>`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;

  ${({ isTransparentBackground }) => {
    if (isTransparentBackground) {
      return css`
        background-color: rgba(255, 255, 255, 0);
        transition: background-color 960ms linear;

        a.logo {
          background-image: url("/assets/images/shipda_logo_white.png");

          ${mobile(css`
            background-image: url("/assets/images/layout/logo.webp");
          `)}
        }

        a.logo-sg {
          background-image: url("/assets/images/sg/bringoodz_logo_white.png");

          ${mobile(css`
            background-image: url("/assets/images/sg/logo-sg.png");
          `)}
        }

        .desktop-header-menu-item > .label {
          color: ${TEXT_COLOR.white_1};
        }

        .custom-header-register-text-button {
          color: ${TEXT_COLOR.white_1};
        }

        &.is-scrolled {
          background-color: rgba(255, 255, 255, 0.8);
          transition: background-color 960ms linear;

          a.logo {
            background-image: url("/assets/images/layout/logo.webp");
          }

          a.logo-sg {
            background-image: url("/assets/images/sg/logo-sg.png");
          }

          .desktop-header-menu-item > .label {
            color: ${TEXT_COLOR.black_2};
          }

          .custom-header-register-text-button {
            color: ${COLOR.grayScale_800};
          }
        }
      `;
    }

    return css`
      background-color: rgba(255, 255, 255, 60);
      ${setElevationStyle("level3")};
    `;
  }};

  ${mobile(css`
    background-color: ${COLOR.wh};
  `)}
`;

export default {
  container,
};
