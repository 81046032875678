import React from "react";
import Image from "next/image";

import { useCheckIsMobile } from "@sellernote/shared/src/utils/common/hook";
import PopUp from "@sellernote/shared/src/sds-v1/components/PopUp";

import Styled from "./index.styles";

export default function PopUpAboutForwardingThcPrice({
  onClose,
}: {
  onClose: () => void;
}) {
  const { isMobile } = useCheckIsMobile();

  return (
    <PopUp
      popUpKey="pop-up-about-forwarding-thc-price-2406061"
      activePeriod={{
        start: new Date("2024-06-06T15:00:00.000Z"), // 24.06.07 자정
        end: new Date("2024-06-30T15:00:00.000Z"), // 24년 6월 내내
      }}
      onClose={onClose}
      content={
        <Styled.container>
          <div className="header">
            <Image
              src={"/assets/images/popUp/logo-notice-new.webp"}
              alt="logo"
              width={isMobile ? 56 : 80}
              height={isMobile ? 56 : 80}
            />

            <div className="title">
              컨테이너 해상 수입 {isMobile && <br />}THC(Terminal Handling
              Charge) {isMobile && <br />}인상 공지
            </div>
          </div>

          <div className="body">
            최근 터미널 비용 상승으로 인하여,
            <br />각 선사들로부터 THC(Terminal Handling Charge)에{" "}
            {isMobile && <br />}대한 {!isMobile && <br />}
            인상이 있어 안내드립니다.
            <br />
            화주분들의 넓은 이해와 적극적인 협조를 부탁드립니다.
            <br />
            <div className="date">
              *적용시점: 6월7일 의뢰분부터 (FCL에 한함)
            </div>
            <br />
            <Image
              src={
                "/assets/images/popUp/PopUpAboutForwardingThcPrice/price-table.webp"
              }
              alt="price table"
              width={300}
              height={288}
            />
            <br />
            <div className="signature">디지털포워더 쉽다(ShipDa) 드림</div>
          </div>
        </Styled.container>
      }
    />
  );
}
