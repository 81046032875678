import { atom } from "recoil";

import { TeamAuthorityInfo } from "@sellernote/shared/src/types/common/team";

// key에 file structure(package이름 포함)를 반영하여 unique가 보장되도록 한다
const KEY_PREFIX = "_shared-for-shipda/user/atoms";

const USER_TEAM_AUTHORITY_INFO = atom<
  Partial<TeamAuthorityInfo & { isPrivate: boolean }>
>({
  key: `${KEY_PREFIX}/USER_TEAM_AUTHORITY_INFO`,
  default: {},
});

export default {
  USER_TEAM_AUTHORITY_INFO,
};
