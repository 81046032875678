import styled, { css } from "styled-components";

import { mobile } from "@sellernote/shared/src/styles/functions";

const logo = styled.span`
  display: inline-block;
  margin-top: 3px;

  ${mobile(css`
    margin-left: 16px;
  `)}
`;

export default {
  logo,
};
