import styled from "styled-components";

import { TEXT_COLOR } from "@sellernote/shared/src/sds-v2/styles/colors";
import { setFontStyle } from "@sellernote/shared/src/sds-v2/styles/typography";

const toggleI18nKey = styled.div<{ needsBlackText: boolean }>`
  padding: 10px 10px 10px 16px;
  cursor: pointer;
  color: ${({ needsBlackText }) =>
    needsBlackText ? TEXT_COLOR.black_2 : TEXT_COLOR.white_1};
  ${setFontStyle("Body4")};
`;

export default {
  toggleI18nKey,
};
