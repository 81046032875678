import { ButtonInModalCommonProps } from "@sellernote/shared/src/headlessComponents/useModal";

import Button from "../../button/Button";

export default function ActionOptionButton({
  actionOptionProps,
}: {
  actionOptionProps: ButtonInModalCommonProps;
}) {
  const { theme, borderType, ...rest } = actionOptionProps;

  return (
    <Button
      size="normal"
      theme={theme ?? "secondary"}
      borderType={borderType ?? "filled"}
      {...rest}
    />
  );
}
