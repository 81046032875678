import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react";
import { useSetAtom } from "jotai";
import { useRouter } from "next/router";
import { Trans, useTranslation } from "next-i18next";

import Button from "@sellernote/shared/src/componentsToMoveToV1/button/Button";
import Icon from "@sellernote/shared/src/componentsToMoveToV1/Icon";
import AUTH_QUERY from "@sellernote/shared/src/queries/forwarding/AUTH_QUERY";
import TextButton from "@sellernote/shared/src/sds-v1/components/button/TextButton";
import InputCheckBox from "@sellernote/shared/src/sds-v1/components/input/InputCheckBox";
import InputText from "@sellernote/shared/src/sds-v1/components/input/InputText";
import { COLOR } from "@sellernote/shared/src/stylesToMoveToV1/constants";
import { ResponseFailureInfo } from "@sellernote/shared/src/types/common/common";
import { handleLoginSubmitByEnter } from "@sellernote/shared/src/utils/common/auth";
import { useCheckIsMobile } from "@sellernote/shared/src/utils/common/hook";
import { makeUserLoginFailedMessage } from "@sellernote/shared/src/utils/forwarding/auth";
import { MARKETING_ATOMS } from "@sellernote/shipda-kr/src/jotaiStates/common/marketing";
import { setNeedToKeepState } from "@sellernote/shipda-kr/src/utils/etc";

import Styled from "./index.styles";

type LoginInputKey = "email" | "password";

export default function LoginModalForm({
  backToAfterRegister,
  className,
  isClickedLoginButton,
  setIsVisibleFindPassword,
  isPromotion,
  onSuccessOfLogin,
  setIsVisibleModal,
  ...propsByNeedsRegisterModal
}: {
  backToAfterRegister?: string;
  className?: string;
  isClickedLoginButton?: boolean;
  setIsVisibleFindPassword: Dispatch<SetStateAction<boolean>>;
  isPromotion?: boolean;
  onSuccessOfLogin?: () => void;
  setIsVisibleModal?: Dispatch<SetStateAction<boolean>>;
} & (
  | {
      /**
       * 회원가입 모달을 띄울지 여부
       */
      needsRegisterModal: true;
      openRegisterModal: () => void;
    }
  | {
      needsRegisterModal?: false;
    }
)) {
  const { pathname, push: routerPush } = useRouter();

  const { isMobile } = useCheckIsMobile();

  const { t } = useTranslation(["auth-new", "common"]);

  const [failureInfo, setFailureInfo] = useState<ResponseFailureInfo>();

  const {
    mutate: login,
    isError: isLoginError,
    ResponseHandler: ResponseHandlerOfLogin,
  } = AUTH_QUERY.useUserLogin({
    onSuccess: onSuccessOfLogin,
    onError: (failureInfo) => {
      setFailureInfo(failureInfo);
    },
  });

  const setNeedToSendGtmLoginEvent = useSetAtom(
    MARKETING_ATOMS.NEED_TO_SEND_GTM_LOGIN_EVENT
  );

  const initialInputState = {
    email: "",
    password: "",
  };

  const [inputState, setInputState] = useState(initialInputState);

  const { email, password } = inputState;

  const [autoLogin, setAutoLogin] = useState(true);

  const errorMsg = useMemo(() => {
    if (!failureInfo) return null;

    return makeUserLoginFailedMessage({ failureInfo });
  }, [failureInfo]);

  function updateInput(key: LoginInputKey, value: string) {
    setInputState({ ...inputState, [key]: value });

    if (failureInfo) {
      setFailureInfo(undefined);
    }
  }

  const handleSignUp = () => {
    const isRegisterPage = pathname === "/register";

    // 회원가입 페이지에서 로그인 모달의 회원가입 버튼을 누른 경우 모달을 닫음
    if (isRegisterPage) {
      setIsVisibleModal?.(false);

      return;
    }

    if (propsByNeedsRegisterModal.needsRegisterModal) {
      setIsVisibleModal?.(false);
      propsByNeedsRegisterModal.openRegisterModal();

      return;
    }

    setNeedToKeepState("yes");

    routerPush({
      pathname: "/register",
      query: {
        // "/register?backTo=&isPromotion=" 와 같이 backToAfterRegister 값이 없을때도 URL에 포함되어 조건 추가
        ...(typeof backToAfterRegister === "string" && {
          backTo: backToAfterRegister,
        }),
        // "/register?backTo=&isPromotion=" 와 같이 isPromotion 값이 없을때도 URL에 포함되어 조건 추가
        ...(typeof isPromotion === "boolean" && {
          isPromotion,
        }),
      },
    });
  };

  const handleLoginSubmit = useCallback(() => {
    // 로그인 이벤트를 보내기 위해 flag셋팅
    setNeedToSendGtmLoginEvent(true);
    login({ accountId: email, password, _autoLogin: autoLogin });
  }, [setNeedToSendGtmLoginEvent, login, email, password, autoLogin]);

  const isQuotationInProgress = backToAfterRegister === "forwarding/quote";

  return (
    <>
      <Styled.container
        className={`${className ? className : ""} login-in-progress`}
      >
        <Styled.formContainer
          onKeyPress={(e) =>
            handleLoginSubmitByEnter({ e, onLoginSubmit: handleLoginSubmit })
          }
        >
          <h2>
            {isClickedLoginButton
              ? t("auth-new:공통_로그인_타이틀")
              : isPromotion
              ? t("auth-new:로그인_프로모션_이용_안내")
              : t("auth-new:로그인_이용_필수_안내")}
          </h2>

          {isQuotationInProgress && (
            <Styled.quotationInProgressContainer>
              <Icon
                type="circleFilledError"
                color={isMobile ? COLOR.secondaryDeepBlue : COLOR.grayScale_700}
                size={1}
              />

              <span className="quotation-in-progress-guide">
                {t("auth-new:로그인_회원가입_입력_유지_안내")}
              </span>
            </Styled.quotationInProgressContainer>
          )}

          <InputText
            name="email"
            label={t("auth-new:공통_로그인_이메일")}
            value={email}
            placeholder={t("auth-new:공통_입력")}
            setValue={(v) => updateInput("email", v as string)}
            borderType="filled"
            valueType="string"
            className="input-email"
            disabledToFocus={isLoginError}
          />

          <InputText
            name="password"
            label={t("common:password")}
            value={password}
            placeholder={t("auth-new:공통_입력")}
            setValue={(v) => updateInput("password", v as string)}
            borderType="filled"
            valueType="password"
            disabledToFocus={isLoginError}
          />

          {errorMsg && <Styled.errorMsg>{errorMsg}</Styled.errorMsg>}

          <Styled.loginOptionContainer>
            <InputCheckBox
              label={t("auth-new:로그인_자동로그인")}
              shape="rect"
              checked={autoLogin}
              handleCheck={() => setAutoLogin(!autoLogin)}
            />

            <TextButton
              theme="default"
              size="12px"
              label={t("auth-new:공통_로그인_비밀번호찾기")}
              handleClick={() => setIsVisibleFindPassword(true)}
            />
          </Styled.loginOptionContainer>

          <Button
            theme="primary"
            size="normal"
            label={t("common:login")}
            handleClick={handleLoginSubmit}
            disabled={!(email && password)}
          />

          <Styled.registerContainer>
            <span className="register-guide">
              {t("auth-new:로그인_회원가입_안내")}
            </span>

            <TextButton
              theme="withIcon"
              label={t("auth-new:로그인_회원가입_버튼")}
              size="14px"
              handleClick={handleSignUp}
              icon="arrowRight"
            />
          </Styled.registerContainer>
        </Styled.formContainer>

        <Styled.imageContainer>
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img
            src="/assets/images/login/login-modal.png"
            className="illust-login"
            alt="illust-login"
          />

          <img
            src="/assets/images/layout/logo.webp"
            className="logo"
            alt="logo"
          />

          <span className="shipda-desc">
            <Trans i18nKey="auth-new:공통_로그인_서비스소개" />
          </span>
        </Styled.imageContainer>
      </Styled.container>

      {ResponseHandlerOfLogin}
    </>
  );
}
