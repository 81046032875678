import React, { Dispatch, SetStateAction, useState } from "react";
import Router, { useRouter } from "next/router";
import { useSetRecoilState } from "recoil";

import Portal from "@sellernote/shared/src/components/Portal";
import Modal from "@sellernote/shared/src/componentsToMoveToV1/Modal";
import TEAM_QUERY from "@sellernote/shared/src/queries/forwarding/TEAM_QUERY";
import { TEAM_ATOMS } from "@sellernote/shipda-kr/src/states/forwarding/team";

import FindPassword from "./FindPassword";
import LoginModalForm from "./LoginModalForm";
import Styled from "./index.styles";

function LoginModalComponent({
  active,
  setIsVisibleModal,
  backToAfterRegister,
  afterLoginCallback,
  className,
  isClickedLoginButton,
  isPromotion,
  ...propsByNeedsRegisterModal
}: {
  active: boolean;
  setIsVisibleModal?: Dispatch<SetStateAction<boolean>>;
  backToAfterRegister?: string;
  /**
   * 의도대로 잘 동작하지 않는 경우가 있으니 사용시 유의할 것
   * (ex. 콜백실행시 userId가 있어야하는 경우는 사용하기 어려움)
   */
  afterLoginCallback?: () => void;
  className?: string;
  isClickedLoginButton?: boolean;
  isPromotion?: boolean;
} & (
  | {
      /**
       * 회원가입 모달을 띄울지 여부
       */
      needsRegisterModal: true;
      openRegisterModal: () => void;
    }
  | {
      needsRegisterModal?: false;
    }
)) {
  // TODO: early return 떼기 (use~ hook 경고 뜨는 것 해결하기)
  if (!active) {
    return null;
  }

  const { query } = useRouter();
  const { backTo } = query;

  const setInvitationTeamList = useSetRecoilState(TEAM_ATOMS.INVITED_TEAM_LIST);

  const { refetch: refetchInvitationTeamList } =
    TEAM_QUERY.useGetInvitedTeamList({
      enabled: false,
      onSuccess: (res) => {
        if (!res.length) return;

        setInvitationTeamList(res);
      },
    });

  const [isVisibleFindPassword, setIsVisibleFindPassword] = useState(false);

  function redirectToBack() {
    if (!backTo || backTo?.includes("/mypage")) Router.replace("/");
    else Router.replace(`${backTo}`);
  }

  const handleSuccessOfLogin = () => {
    refetchInvitationTeamList();

    setIsVisibleModal?.(false);
    afterLoginCallback?.();
  };

  return (
    <Styled.container
      className={`${className ? className : ""} ${
        isVisibleFindPassword ? "find-password-modal" : ""
      }`}
    >
      <Modal
        active
        uiType="contentWithCustomBody"
        title=""
        body={
          isVisibleFindPassword ? (
            <FindPassword setIsVisibleFindPassword={setIsVisibleFindPassword} />
          ) : (
            <LoginModalForm
              {...{
                backToAfterRegister,
                isClickedLoginButton,
                setIsVisibleFindPassword,
                isPromotion,
                ...propsByNeedsRegisterModal,
              }}
              onSuccessOfLogin={handleSuccessOfLogin}
              setIsVisibleModal={setIsVisibleModal}
            />
          )
        }
        onClose={
          setIsVisibleModal
            ? () => {
                setIsVisibleModal(false);
              }
            : () => {
                redirectToBack();
              }
        }
        className="custom-login-modal"
      />
    </Styled.container>
  );
}

export default function LoginModal({
  ...props
}: Parameters<typeof LoginModalComponent>[0] & { usePortal?: boolean }) {
  return props.usePortal ? (
    <Portal selector="#app-portal">
      <LoginModalComponent {...props} />
    </Portal>
  ) : (
    <LoginModalComponent {...props} />
  );
}
