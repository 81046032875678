import { Trans } from "react-i18next";

import CallSolidIcon from "@sellernote/shared/src/sds-v2/components/svgIcons/CallSolidIcon";

import TextButton from "../button/TextButton";
import EnvelopeFilledIcon from "../svgIcons/EnvelopeFilledIcon";
import Styled from "./index.styles";

interface ContactInfo {
  name: React.ReactNode;
  phone: string;
  email: string;
}

export default function ContactList({ name, phone, email }: ContactInfo) {
  return (
    <Styled.container className="contact-list">
      <Styled.name>
        {name}{" "}
        <Styled.role>
          <Trans i18nKey="sds-v2:ContactList_매니저" />
        </Styled.role>
      </Styled.name>

      <Styled.phone>
        <TextButton
          theme="gray"
          fontSize={16}
          fontWeight={400}
          handleClick={() => {
            window.location.href = `tel:${phone}`;
          }}
          label={phone}
          iconInfo={{
            Icon: CallSolidIcon,
            position: "right",
          }}
        />
      </Styled.phone>

      <Styled.email>
        <TextButton
          theme="gray"
          fontSize={16}
          fontWeight={400}
          handleClick={() => {
            window.location.href = `mailto:${email}`;
          }}
          label={email}
          iconInfo={{
            Icon: EnvelopeFilledIcon,
            position: "right",
          }}
        />
      </Styled.email>
    </Styled.container>
  );
}
