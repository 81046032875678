import styled, { css } from "styled-components";

import { mobile } from "@sellernote/shared/src/styles/functions";

import { COLOR, TEXT_COLOR } from "../../../styles/colors";
import { setFontStyle } from "../../../styles/typography";

import { StepItemProps } from "./StepItem";

const container = styled.div`
  position: absolute;
  top: 26px;
  right: 56px;
`;

const stepList = styled.ul`
  display: flex;
  justify-content: flex-end;
`;

const stepItemContainer = styled.li<Pick<StepItemProps, "isActive">>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 72px;
  cursor: default;

  ${mobile(css`
    width: auto;

    &:not(:last-child) {
      margin-right: 8px;
    }
  `)}

  &:first-child > div::before,
  &:last-child > div::after {
    display: none;
  }
`;

const stepPoint = styled.div<Pick<StepItemProps, "isActive">>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 30px;
  background-color: ${({ isActive }) =>
    isActive ? COLOR.primary_600 : COLOR.grayScale_400};

  &::before,
  &::after {
    position: absolute;
    top: 11px;
    display: block;
    content: "";
    width: 24px;
    height: 1px;
    background-color: ${COLOR.grayScale_400};

    ${mobile(css`
      display: none;
    `)}
  }

  &::before {
    right: 24px;
  }

  &::after {
    left: 24px;
  }

  .step-number {
    ${setFontStyle("SubHead2")};
    color: ${TEXT_COLOR.white_1};
    line-height: 24px;
  }
`;

export default {
  container,
  stepList,
  stepItemContainer,
  stepPoint,
};
