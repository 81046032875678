import { useQueryClient } from "react-query";
import { useRouter } from "next/router";

import { ShipdaCurrentLanguage } from "@sellernote/shared/src/i18n/i18nForShipda";
import { YEAR_AS_MILLISECONDS } from "@sellernote/shared/src/utils/common/date";

export default function useSelectLanguage() {
  const {
    pathname,
    query,
    asPath,
    locale: currentLocale,
    push,
    reload,
  } = useRouter();

  const queryClient = useQueryClient();

  const handleLanguageSelect = async (locale: "ko" | "en") => {
    const isSameLanguage = currentLocale === locale;
    if (isSameLanguage) {
      return;
    }

    document.cookie = `NEXT_LOCALE=${locale}; path=/; max-age=${YEAR_AS_MILLISECONDS};`;

    ShipdaCurrentLanguage.currentLanguage = locale;

    try {
      await push({ pathname, query }, asPath, { locale });

      reload();

      const channelIO = (window as any).ChannelIO;

      if (channelIO) {
        channelIO("updateUser", { language: locale });
      }

      await queryClient.invalidateQueries();
    } catch (error) {
      console.error("언어 변경 중 오류 발생:", error);
    }
  };

  return { handleLanguageSelect };
}
