import styled, { css } from "styled-components";

import { mobile } from "@sellernote/shared/src/styles/functions";
import { setFontStyle } from "@sellernote/shared/src/sds-v2/styles/typography";

const title = styled.div`
  ${setFontStyle("Head6")};

  ${mobile(css`
    ${setFontStyle("SubHead2")};
  `)}
`;

const newcomerGuide = styled.div`
  padding: 16px 0px;

  ${mobile(css`
    padding: 0px;
  `)}
`;

const newcomerGuideManager = styled.div`
  display: flex;
  margin-top: 24px;
`;

export default {
  title,
  newcomerGuide,
  newcomerGuideManager,
};
