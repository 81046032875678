import { useState } from "react";
import { Trans } from "react-i18next";

import usePaging, {
  PagingProps,
} from "@sellernote/shared/src/headlessComponents/usePaging";
import { useCheckIsMobile } from "@sellernote/shared/src/utils/common/hook";
import EllipsisHorizontalIcon from "@sellernote/shared/src/sds-v2/components/svgIcons/EllipsisHorizontalIcon";
import FourChevronLeftIcon from "@sellernote/shared/src/sds-v2/components/svgIcons/FourChevronLeftIcon";
import FourChevronRightIcon from "@sellernote/shared/src/sds-v2/components/svgIcons/FourChevronRightIcon";

import { COLOR } from "../../../styles/colors";

import InputTextField from "../../form/InputTextField";
import Styled from "./index.styles";

export default function Pagination({
  pageSize,
  currentPage,
  handleClickPage,
  isZeroBasedPage,
  className,
}: PagingProps) {
  const { isMobile } = useCheckIsMobile();

  const [inputValue, setInputValue] = useState<string>();

  const {
    adjustedCurrentPage,
    canJumpToPreviousPageGroup,
    canJumpToNextPageGroup,
    visiblePageLength,
    firstPageOfVisiblePageGroup,
    firstPageOfNextPageGroup,
    firstPageOfPreviousPageGroup,
    isVisiblePagination,
    isVisibleArrowIcon,
    isVisibleMoveToFirstPage,
    isVisibleMoveToLastPage,
    disabledPrevArrowIcon,
    disabledNextArrowIcon,
  } = usePaging({
    pageSize,
    currentPage,
    isZeroBasedPage,
  });

  const handlePreviousPageMove = () => {
    if (disabledPrevArrowIcon) return;

    if (isMobile) {
      setInputValue(undefined);
      return handleClickPage(currentPage - 1);
    }

    return handleClickPage(firstPageOfPreviousPageGroup);
  };

  const handleNextPageMove = () => {
    if (disabledNextArrowIcon) return;

    if (isMobile) {
      setInputValue(undefined);
      return handleClickPage(currentPage + 1);
    }

    handleClickPage(firstPageOfNextPageGroup);
  };

  const handleMobileInputPageMove = (value: string) => {
    if (!value) return setInputValue(value);

    const pageNumber = isZeroBasedPage ? Number(value) - 1 : Number(value);

    if (value === "0" || pageNumber < 0) {
      setInputValue("1");
      return handleClickPage(isZeroBasedPage ? 0 : 1);
    }

    if (pageNumber > pageSize) {
      setInputValue(`${pageSize}`);
      return handleClickPage(isZeroBasedPage ? pageSize - 1 : pageSize);
    }

    setInputValue(value);
    handleClickPage(pageNumber);
  };

  const handleMoveFirstPage = () => {
    if (isZeroBasedPage) {
      handleClickPage(0);
      return;
    }
    handleClickPage(1);
  };

  const handleMoveLastPage = () => {
    if (isZeroBasedPage) {
      handleClickPage(pageSize - 1);
      return;
    }
    handleClickPage(pageSize);
  };

  const arrowIconSize = isMobile ? 32 : 40;

  return (
    <>
      {isVisiblePagination && (
        <Styled.container
          className={`${className ? className : ""} pagination`}
        >
          {isVisibleArrowIcon && (
            <FourChevronLeftIcon
              width={arrowIconSize}
              height={arrowIconSize}
              color={
                disabledPrevArrowIcon
                  ? COLOR.grayScale_300
                  : COLOR.grayScale_600
              }
              onClick={handlePreviousPageMove}
              className={`move-left-button ${
                disabledPrevArrowIcon ? "disabled" : ""
              }`}
            />
          )}

          {isMobile ? (
            <Styled.mobileContainer>
              <InputTextField
                labelInfo={{
                  label: <Trans i18nKey="sds-v2:Pagination_현재_페이지" />,
                  isLabelHidden: true,
                }}
                inputMode="numeric"
                value={inputValue ?? `${adjustedCurrentPage}`}
                setValue={handleMobileInputPageMove}
                width="64px"
              />

              <span className="mobile-total-page">
                <span className="slash">/</span>
                {pageSize} <Trans i18nKey="sds-v2:Pagination_페이지" />
              </span>
            </Styled.mobileContainer>
          ) : (
            <>
              {isVisibleMoveToFirstPage && (
                <Styled.moveToFirstPage>
                  <Styled.pageNumber onClick={handleMoveFirstPage}>
                    1
                  </Styled.pageNumber>

                  <EllipsisHorizontalIcon
                    width={24}
                    height={24}
                    color={COLOR.grayScale_500}
                  />
                </Styled.moveToFirstPage>
              )}

              <Styled.pageIndicator>
                {[...Array(visiblePageLength)].map((_, i) => {
                  const pageNumber = firstPageOfVisiblePageGroup + i;

                  /**
                   * 다음페이지를 셋팅하는 함수로 전달되는 페이지는 0기반 페이징여부에 따라 처리가 필요함
                   */
                  const pageNumberForAction = isZeroBasedPage
                    ? pageNumber - 1
                    : pageNumber;

                  const selected = adjustedCurrentPage === pageNumber;

                  return (
                    <Styled.pageNumber
                      className={`item ${selected ? "selected" : ""}`}
                      key={i}
                      onClick={() => handleClickPage(pageNumberForAction)}
                      moreThanThreeDigits={pageNumber > 99}
                    >
                      {pageNumber}
                    </Styled.pageNumber>
                  );
                })}
              </Styled.pageIndicator>

              {isVisibleMoveToLastPage && (
                <Styled.moveToLastPage>
                  <EllipsisHorizontalIcon
                    width={24}
                    height={24}
                    color={COLOR.grayScale_500}
                  />

                  <Styled.pageNumber
                    onClick={handleMoveLastPage}
                    moreThanThreeDigits={pageSize > 99}
                  >
                    {pageSize}
                  </Styled.pageNumber>
                </Styled.moveToLastPage>
              )}
            </>
          )}

          {isVisibleArrowIcon && (
            <FourChevronRightIcon
              width={arrowIconSize}
              height={arrowIconSize}
              color={
                disabledNextArrowIcon
                  ? COLOR.grayScale_300
                  : COLOR.grayScale_600
              }
              onClick={handleNextPageMove}
              className={`move-right-button ${
                disabledNextArrowIcon ? "disabled" : ""
              }`}
            />
          )}
        </Styled.container>
      )}
    </>
  );
}
