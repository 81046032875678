import { useCallback } from "react";
import { useQueryClient } from "react-query";

import TEAM_QUERY, {
  TEAM_QUERY_KEY_GEN,
} from "@sellernote/shared/src/queries/forwarding/TEAM_QUERY";
import { USER_QUERY_KEY_GEN } from "@sellernote/shared/src/queries/forwarding/USER_QUERY";

/**
 * 직전에 팀 계정을 사용했는지 확인하여 백그라운드에서 팀계정으로 전환하는 hook.
 * 아래 사항을 전제로 동작하니 상황이 바뀌면 코드 수정이 필요함
 * <전제>
 * - 유저는 1개의 개인계정(필수), 0~n개의 팀계정을 가지고 있다
 * - 로그인하면 항상 개인계정으로 로그인된다. (가장 중요한 전제)
 * - /users/detail API response 로 마지막 접속한 팀의 teamId 가 온다.
 *
 * <동작방식>
 * 1) 팀 계정으로 스위칭할때 서버에서 teamId를 저장
 * 2) 재로그인 또는 토큰 만료 후 refresh 되는 경우, 로그인 후 /users/detail API 를 호출하게 되는데,
 * 이때 response 의 latestUsedTeamId(마지막으로 접속한 team id) 확인하여 백그라운드에서 팀계정으로 스위칭한다.
 */
export default function useSwitchLatestUsedTeamAccount() {
  const queryClient = useQueryClient();

  const { mutate: switchAccount } = TEAM_QUERY.useSwitchAccount({
    onSuccess: () => {
      queryClient.invalidateQueries(TEAM_QUERY_KEY_GEN.getAccountInfo());
      queryClient.invalidateQueries(USER_QUERY_KEY_GEN.getUserInfo());
    },
    // 계정 전환 실패에 대한 처리는 하지 않음 (바꾸는데 실패하면 백그래운드에서 실패하고 그냥 개인계정이 사용됨)
  });

  /**
   * 직전에 팀계정을 사용했다면 팀계정으로 스위칭함
   */
  const checkLatestUsedTeamAccountAndSwitch = useCallback(
    (currentTeamId: number, lastLoginTeamId: number | null) => {
      // 현재 접속한 teamId 와 마지막으로 사용한 teamId 가 동일하면 return;
      if (!lastLoginTeamId || currentTeamId === lastLoginTeamId) return;

      // 직전에 팀계정으로 로그인했었다면 팀 계정으로 스위칭
      if (lastLoginTeamId && !isNaN(lastLoginTeamId)) {
        switchAccount({ pathParams: { teamId: lastLoginTeamId } });
      }
    },
    [switchAccount]
  );

  return {
    checkLatestUsedTeamAccountAndSwitch,
  };
}
