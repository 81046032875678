import ContactList from "@sellernote/shared/src/sds-v2/components/ContactList";
import Divide from "@sellernote/shared/src/sds-v2/components/Divide";
import { COLOR } from "@sellernote/shared/src/sds-v2/styles/colors";

import { ManagerInfoProps } from "../../../../..";
import Styled from "./index.styles";

/**
 * 마지막 요소에는 Divide를 추가하지 않고, marginTop만 전달
 */
export default function ManagerListForMobile({
  managerList,
}: {
  managerList: ManagerInfoProps[];
}) {
  return (
    <Styled.container>
      {managerList?.map(({ name, phone, email }, i, arr) => {
        const isLastObject = arr.length - 1 === i;

        return (
          <Styled.mobileContactListContainer key={email}>
            <ContactList name={name} phone={phone} email={email} />

            {isLastObject ? (
              <Styled.invisibleDivide />
            ) : (
              <Divide
                type="horizontal"
                lineStyle="line"
                thickness={1}
                color={COLOR.grayScale_200}
                width="100%"
                className="custom-divide"
              />
            )}
          </Styled.mobileContactListContainer>
        );
      })}
    </Styled.container>
  );
}
