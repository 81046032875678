import React from "react";
import { useTranslation } from "next-i18next";

import { ButtonSizeV2 } from "@sellernote/shared/src/headlessComponents/button/useButton";
import Button from "@sellernote/shared/src/sds-v2/components/button/Button";

import useLogout from "./useLogout";
import Styled from "./index.styles";

export default function Logout({
  buttonInfo,
}: {
  buttonInfo: { size: ButtonSizeV2; width?: string };
}) {
  const { t } = useTranslation(["containers"]);

  const { ConfirmLogoutModal, handleConfirmLogoutModalOpen } = useLogout();

  return (
    <Styled.logoutContainer className="logout">
      <Button
        theme="critical"
        borderType="outlined"
        size={buttonInfo.size}
        width={buttonInfo.width}
        label={t("containers:Layout.로그아웃")}
        handleClick={handleConfirmLogoutModalOpen}
      />

      {ConfirmLogoutModal}
    </Styled.logoutContainer>
  );
}
