import styled, { css } from "styled-components";

import { mobile } from "@sellernote/shared/src/styles/functions";
import { TEXT_COLOR } from "@sellernote/shared/src/sds-v2/styles/colors";
import { setFontStyle } from "@sellernote/shared/src/sds-v2/styles/typography";

const completeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 78px;

  ${mobile(css`
    padding-top: 28px;
  `)};
`;

const completeTitle = styled.div`
  margin-bottom: 72px;
  ${setFontStyle("SubHead1")};
  color: ${TEXT_COLOR.black_2};

  ${mobile(css`
    ${setFontStyle("SubHead2")};
  `)};
`;

const completeDesc = styled.div`
  margin-top: 72px;
  ${setFontStyle("Body2")};
  color: ${TEXT_COLOR.black_2};

  ${mobile(css`
    margin-top: 24px;
  `)};
`;

export default {
  completeContainer,
  completeTitle,
  completeDesc,
};
