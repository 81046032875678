import { FC } from "react";

import { useCheckIsMobile } from "@sellernote/shared/src/utils/common/hook";

interface PagingProps {
  pageSize: number;
  currentPage: number;
  currentPageData?: React.ReactNode;
  handleClickPage: (page: number) => void;
  isZeroBasedPage?: boolean; // 페이지 번호가 0으로 시작하는지
  selectedCount?: number;
  resetsSelectedCountOnPageChange?: boolean; // 페이지 이동시 선택된 항목들 초기화 여부에 따라 다른 텍스트 표시를 위한 변수
  className?: string;
}
type PagingComponent = FC<PagingProps>;

function usePaging({
  pageSize,
  currentPage,
  isZeroBasedPage,
}: Pick<
  PagingProps,
  "pageSize" | "currentPage" | "isZeroBasedPage" | "selectedCount"
>) {
  const { isMobile } = useCheckIsMobile();

  const MAX_VISIBLE_PAGE_COUNT = 5; // 최대 5개의 페이지 번호가 보임

  // 페이지번호가 0으로 시작하는 것과 관계없이 이후의 코드부터는 1부터 시작하는 것으로 간주됨
  const adjustedCurrentPage = isZeroBasedPage ? currentPage + 1 : currentPage;

  const pageGroupSize = Math.ceil(pageSize / MAX_VISIBLE_PAGE_COUNT);

  const currentPageGroupNum = Math.ceil(
    adjustedCurrentPage / MAX_VISIBLE_PAGE_COUNT
  );

  const firstPageOfVisiblePageGroup =
    (currentPageGroupNum - 1) * MAX_VISIBLE_PAGE_COUNT + 1;

  const visiblePageLength =
    firstPageOfVisiblePageGroup + MAX_VISIBLE_PAGE_COUNT - 1 <= pageSize
      ? MAX_VISIBLE_PAGE_COUNT
      : pageSize - firstPageOfVisiblePageGroup + 1;

  const isArrowVisible = pageSize > MAX_VISIBLE_PAGE_COUNT; // 최대 표시페이지 수를 넘어가면 화살표가 보임

  const canJumpToPreviousPageGroup = currentPageGroupNum > 1;
  const canJumpToNextPageGroup = currentPageGroupNum < pageGroupSize;

  const firstPageOfPreviousPageGroup = isZeroBasedPage
    ? (currentPageGroupNum - 1) * MAX_VISIBLE_PAGE_COUNT - 1
    : (currentPageGroupNum - 1) * MAX_VISIBLE_PAGE_COUNT;

  const firstPageOfNextPageGroup = isZeroBasedPage
    ? currentPageGroupNum * MAX_VISIBLE_PAGE_COUNT
    : currentPageGroupNum * MAX_VISIBLE_PAGE_COUNT + 1;

  const isVisiblePagination = pageSize > 1;

  const isVisibleArrowIcon = isMobile ? pageSize > 1 : pageSize > 5;

  const isVisibleMoveToFirstPage =
    pageSize > 10 && firstPageOfVisiblePageGroup !== 1;

  const isVisibleMoveToLastPage =
    pageSize > 10 && firstPageOfNextPageGroup < pageSize;

  const disabledPrevArrowIcon = isMobile
    ? adjustedCurrentPage <= 1
    : firstPageOfVisiblePageGroup === 1;

  const disabledNextArrowIcon = isMobile
    ? adjustedCurrentPage >= pageSize
    : !canJumpToNextPageGroup;

  return {
    adjustedCurrentPage,
    isArrowVisible,
    canJumpToPreviousPageGroup,
    canJumpToNextPageGroup,
    currentPageGroupNum,
    visiblePageLength,
    firstPageOfVisiblePageGroup,
    MAX_VISIBLE_PAGE_COUNT,
    firstPageOfNextPageGroup,
    firstPageOfPreviousPageGroup,
    isVisiblePagination,
    isVisibleArrowIcon,
    isVisibleMoveToFirstPage,
    isVisibleMoveToLastPage,
    disabledPrevArrowIcon,
    disabledNextArrowIcon,
  };
}

export default usePaging;

export type { PagingProps, PagingComponent };
