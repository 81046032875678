import React from "react";
import { Trans } from "next-i18next";

import {
  BooleanString,
  Coordinate,
  Port,
} from "@sellernote/shared/src/types/common/common";

export function getPortCoordinate(port?: Port): Coordinate | undefined {
  if (!port) {
    return;
  }

  if (!port.lat || !port.lng) {
    return;
  }

  return {
    lat: port.lat,
    lng: port.lng,
    name: port.nameKR || port.name || port.nameEN,
  };
}

/**
 * 검사 대상 값이 boolean인 경우, truthy나 falsy값이 아닌 실제 boolean의 true, false만 체크하는데 사용
 * 각 경우에 answerTrue[False]로 할당한 값이 return 됨.
 * 2, 3번째 인자로 true/false 문자열, 번역 컴포넌트를 넣으면 맞춰서 반환.
 */
export function checkBooleanAndReturnString(
  val: any,
  answerTrue = <Trans i18nKey="common:yes" />,
  answerFalse = <Trans i18nKey="common:no" />
) {
  if (typeof val !== "boolean") {
    return;
  }

  return val ? answerTrue : answerFalse;
}

/**
 * 이번 브라우저 세션에서 의뢰를 한 적이 있다고 체크
 */
export function setHasOrderExperience() {
  if ((process as any).browser) {
    sessionStorage.setItem("hasOrderExperience", "yes");
  }
}

/**
 * 이번 브라우저 세션에서 의뢰를 한 적이 있는지 확인
 */
export function getHasOrderExperience(): boolean {
  if ((process as any).browser) {
    if (window.sessionStorage.getItem("hasOrderExperience") === "yes") {
      return true;
    }
  }
  return false;
}

/**
 * 임시로 주문 state를 보존할 필요가 있다고 체크 (login후에도 state를 남겨야될때 사용)
 */
export function setNeedToKeepState(val: BooleanString) {
  if ((process as any).browser) {
    sessionStorage.setItem("needToKeepState", val);
  }
}

/**
 * 임시로 주문 state를 보존할 필요가 있는지 확인
 */
export function getNeedToKeepState(): boolean {
  if ((process as any).browser) {
    if (window.sessionStorage.getItem("needToKeepState") === "yes") {
      return true;
    }
  }

  return false;
}

/**
 * 예, 아니오 옵션
 */
export const yesOrNotOptionList = [
  { label: <Trans i18nKey="common:no" />, value: "no" },
  { label: <Trans i18nKey="common:yes" />, value: "yes" },
];

/**
 * 가능, 불가능 옵션
 */
export const possibleOrNotOptionList = [
  { label: <Trans i18nKey="common:possible" />, value: "yes" },
  { label: <Trans i18nKey="common:impossible" />, value: "no" },
];

/**
 * S3 에서 업로드가 되지 않는 mime type 을 업로드가 가능한 mime type으로 변환.
 * @param mimeType
 */
export function getMimeType(mimeType: string) {
  // hwp 파일은 mime type 이 잡히지 않아 "" 로 오기때문에 이렇게 처리함.
  if (mimeType === "") return "application/haansofthwp";

  if (mimeType === "application/haansoftdocx")
    return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";

  if (mimeType === "application/msword") return "application/vnd.ms-excel ";

  return mimeType;
}
