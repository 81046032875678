import styled, { css } from "styled-components";

import { mobile } from "@sellernote/shared/src/styles/functions";
import { COLOR } from "@sellernote/shared/src/sds-v2/styles/colors";
import { setFontStyle } from "@sellernote/shared/src/sds-v2/styles/typography";

const container = styled.div`
  margin-top: 16px;

  ${mobile(css`
    margin-top: 24px;
  `)}

  .manager-info-modal {
    & > div:first-child {
      width: 960px;
      height: 500px;
    }

    & > div .body {
      margin-top: 26px;
    }

    & > div,
    & > div .body {
      overflow: auto;
    }
  }
`;

const salesMangerInfo = styled.div`
  ${mobile(css`
    margin-top: 24px;
  `)}

  .text-button > .label {
    color: ${COLOR.wh};
  }

  .text-button > .icon svg path {
    fill: ${COLOR.wh};
  }

  .sales-manager-info-button {
    padding-left: 0;
  }

  ${mobile(css`
    display: block;
    margin-left: 0;
  `)};
`;

const salesMangerGuide = styled.div<{ textColor: string; buttonColor: string }>`
  margin-top: 4px;

  .manager-desc {
    ${setFontStyle("Body3")}
    color: ${({ textColor }) => textColor};
  }

  .text-button {
    > .label {
      color: ${({ buttonColor }) => buttonColor};
    }
  }
`;

export default {
  container,
  salesMangerInfo,
  salesMangerGuide,
};
