import React from "react";

import { SvgIconComponent } from "@sellernote/shared/src/headlessComponents/useSvgIcon";

import Styled from "./index.styles";

type TextButtonTheme = "primary" | "gray" | "critical" | "white";

type IconPosition = "left" | "right";

interface TextButtonIconInfo {
  Icon: SvgIconComponent;
  position: IconPosition;
  /** 기본값 : 14 */
  iconWidth?: number;
  /** 기본값 : 14 */
  iconHeight?: number;
}

interface BtnAreaPadding {
  left?: number;
  right?: number;
  top?: number;
  bottom?: number;
}

interface TextButtonProps {
  theme: TextButtonTheme;
  /**
   * px 단위의 숫자만 입력.
   */
  fontSize: number;
  fontWeight?: number;
  label: string;
  handleClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled?: boolean;
  iconInfo?: TextButtonIconInfo;
  /**
   * figma layer 에 btn area (click, touch 영역) 가 별도로 설정되는 경우.
   */
  btnAreaPadding?: BtnAreaPadding;
  hasUnderline?: boolean;
  buttonType?: "button" | "submit" | "reset";
  className?: string;
}

export type {
  TextButtonTheme,
  IconPosition,
  TextButtonIconInfo,
  BtnAreaPadding,
  TextButtonProps,
};

export default function TextButton({
  theme,
  fontSize,
  fontWeight,
  label,
  handleClick,
  disabled,
  iconInfo,
  btnAreaPadding,
  hasUnderline,
  buttonType,
  className,
}: TextButtonProps) {
  return (
    <Styled.container
      theme={theme}
      fontSize={fontSize}
      fontWeight={fontWeight}
      onClick={handleClick}
      disabled={disabled}
      iconPosition={iconInfo?.position}
      btnAreaPadding={btnAreaPadding}
      hasUnderline={hasUnderline}
      type={buttonType ?? "submit"}
      className={`${className ? className : ""} text-button`}
    >
      {iconInfo?.position === "left" && (
        <iconInfo.Icon
          width={iconInfo?.iconWidth ?? 14}
          height={iconInfo?.iconHeight ?? 14}
          className="icon"
        />
      )}

      <div className="label">{label}</div>

      {iconInfo?.position === "right" && (
        <iconInfo.Icon
          width={iconInfo?.iconWidth ?? 14}
          height={iconInfo?.iconHeight ?? 14}
          className="icon"
        />
      )}
    </Styled.container>
  );
}
