import { useAtom, useAtomValue } from "jotai";
import Image from "next/image";
import { useRouter } from "next/router";

import { ShipdaCurrentLanguage } from "@sellernote/shared/src/i18n/i18nForShipda";
import initChatButtonUI from "@sellernote/shared/src/services/chat/initChatButtonUI";
import openChatModal from "@sellernote/shared/src/services/chat/openChatModal";
import { useCheckIsMobile } from "@sellernote/shared/src/utils/common/hook";
import { COMMON_LAYOUT_ATOMS } from "@sellernote/shipda-kr/src/jotaiStates/common/layout";

import { MOBILE_CHAT_BUTTON_HEIGHT } from "../../constants/layout";
import Styled from "./index.styles";

export default function FloatingChatButton() {
  const floatingButtonAreaPosition = useAtomValue(
    COMMON_LAYOUT_ATOMS.FLOATING_BUTTON_AREA_POSITION
  );

  const [unreadCount, setUnreadCount] = useAtom(
    COMMON_LAYOUT_ATOMS.CHAT_BUTTON_UNREAD_COUNT
  );

  const { isMobile } = useCheckIsMobile();

  initChatButtonUI(setUnreadCount);

  const { asPath } = useRouter();
  const isOnMyPage = asPath.includes("/mypage");
  // 데크스탑 마이페이지에서는 헤더에서 채널톡 아이콘을 표시하므로 플로팅 버튼 영역에서는 표시하지 않는다.
  if (isOnMyPage && !isMobile) return null;

  return (
    <Styled.chatButton
      className="custom-channel-io-button"
      // 플로팅 버튼영역의 포지션을 기준으로 위치를 잡는다
      bottomPosition={floatingButtonAreaPosition}
      onClick={openChatModal}
    >
      {typeof unreadCount === "number" && unreadCount > 0 && (
        <div className="unread-count">{unreadCount}</div>
      )}

      {isMobile ? (
        <Image
          src="/assets/images/chat_button_mobile.webp"
          alt="channel-io-button"
          width={MOBILE_CHAT_BUTTON_HEIGHT}
          height={MOBILE_CHAT_BUTTON_HEIGHT}
        />
      ) : ShipdaCurrentLanguage.currentLanguage === "ko" ? (
        <Image
          src={"/assets/images/chat_button_ko.webp"}
          alt="channel-io-button"
          width={168}
          height={50}
        />
      ) : (
        <Image
          src={"/assets/images/chat_button_en.webp"}
          alt="channel-io-button"
          width={137}
          height={50}
        />
      )}
    </Styled.chatButton>
  );
}
