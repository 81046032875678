import React from "react";
import Link from "next/link";

import { addEventToGTMDataLayer } from "@sellernote/shared/src/utils/common/gtm";

import { Submenu } from "../useMenuList";

export default function MobileMenuItem({
  submenu,
  isSelectedSubmenu,
}: {
  submenu: Submenu;
  isSelectedSubmenu: boolean;
}) {
  if (!isSelectedSubmenu) return null;

  const isExternalLink = submenu.path.includes("http");

  const handleSubMenuSelect = () => {
    if (!submenu.gtmEvent) {
      return;
    }

    addEventToGTMDataLayer(submenu.gtmEvent);
  };

  return (
    <li className="sub-menu-item">
      <Link href={submenu.path}>
        <a
          target={isExternalLink ? "_blank" : "_top"}
          onClick={handleSubMenuSelect}
        >
          {submenu.labelForFooter}
        </a>
      </Link>
    </li>
  );
}
