import {
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import { InputTextFieldProps } from "@sellernote/shared/src/headlessComponents/form/useInputTextField";
import useClickOutsideOfElement from "@sellernote/shared/src/hooks/common/useClickOutsideOfElement";
import newId from "@sellernote/shared/src/utils/common/newId";
import regEx from "@sellernote/shared/src/utils/common/regEx";
import {
  getTrimmedValue,
  removeLeadingBlank,
} from "@sellernote/shared/src/utils/common/string";
import ExclamationTriangleIcon from "@sellernote/shared/src/sds-v2/components/svgIcons/ExclamationTriangleIcon";
import InfoFilledIcon from "@sellernote/shared/src/sds-v2/components/svgIcons/InfoFilledIcon";
import XMarkCircleIcon from "@sellernote/shared/src/sds-v2/components/svgIcons/XMarkCircleIcon";

import { COLOR, TEXT_COLOR } from "../../../styles/colors";

import Button from "../../button/Button";
import Tooltip from "../../Tooltip";
import Styled from "./index.styles";

export default function InputTextField({
  inputMode = "text",
  inputType,
  numberType = "integer",
  labelInfo,
  value,
  setValue,
  width,
  placeholder,
  errorMessage,
  addonButton,
  disabled,
  isAutoComplete,
  className,
  readOnly = false,
  required = false,
  onKeyDown,
  size = "default",
  rhfInputRef,
}: InputTextFieldProps) {
  const { t } = useTranslation(["sds-v2"]);

  // label id를 unique하게 사용하기 위함
  const [inputId] = useState(() => newId("input-text-field-id-"));

  const inputRef = useRef<HTMLInputElement>(null);

  const addonRef = useRef<HTMLDivElement>(null);

  const [isInsideContainer, setIsInsideContainer] = useState(false);

  const { targetElementRef: containerRef } = useClickOutsideOfElement({
    onClickInside: () => {
      setIsInsideContainer(true);
    },
    onClickOutside: () => {
      setIsInsideContainer(false);
    },
  });

  const focusToInput = () => {
    inputRef.current?.focus();
  };

  useImperativeHandle(rhfInputRef, () => ({
    focus: focusToInput,
  }));

  const Addon = useMemo(() => {
    if (addonButton) {
      return (
        <Styled.addon size={size} className="button-container" ref={addonRef}>
          {addonButton.extraLabel && (
            <div className="extra-label">{addonButton.extraLabel}</div>
          )}

          <Button
            label={addonButton.label}
            theme="secondary"
            borderType="filled"
            size="small"
            buttonType={addonButton.buttonType}
            handleClick={() => {
              addonButton.onClick();
              focusToInput();
            }}
            disabled={!addonButton.active || disabled}
          />
        </Styled.addon>
      );
    }

    if (errorMessage) {
      return (
        <Styled.addon size={size} className="error">
          <ExclamationTriangleIcon
            width={16}
            height={16}
            color={COLOR.error_400}
          />
        </Styled.addon>
      );
    }

    if (isInsideContainer && value && !disabled && !readOnly) {
      return (
        <Styled.addon size={size} className="clear">
          <XMarkCircleIcon
            width={16}
            height={16}
            color={COLOR.grayScale_400}
            /**
             * input에 focus가 되어있는 상태에서 x버튼을 누르면 blur가 먼저 발생하고
             * 그 다음에 onClick이 발생하는데, 이를 방지하기 위해 onMouseDown을 사용해서 이벤트 전파를 막음                 *
             * https://stackoverflow.com/questions/17769005/onclick-and-onblur-ordering-issue
             */
            onMouseDown={(e) => e.preventDefault()}
            onClick={() => {
              setValue("");
              focusToInput();
            }}
          />
        </Styled.addon>
      );
    }

    return null;
  }, [
    addonButton,
    disabled,
    errorMessage,
    isInsideContainer,
    readOnly,
    setValue,
    size,
    value,
  ]);

  const setValidatedValue = useCallback(
    (rawVal: string) => {
      const val = removeLeadingBlank(rawVal);

      if (!val) {
        setValue("");
        return;
      }

      if (inputMode === "numeric" || inputMode === "decimal") {
        if (Number.isNaN(Number(val))) {
          return;
        }

        if (numberType === "integer") {
          setValue(String(parseInt(val)));
          return;
        }
      }

      setValue(val);
    },
    [inputMode, numberType, setValue]
  );

  const handleBlur = () => {
    const hasBlank = regEx.blank.test(String(value));

    if (!value || !hasBlank) return;

    setValue(getTrimmedValue(value));
  };

  return (
    <Styled.container
      className={`${className ? className : ""} input-text-field`}
      width={width}
      disabled={disabled}
      hasError={!!errorMessage}
      isHorizontalLabel={!!labelInfo.isHorizontal}
      ref={containerRef}
      widthOfButtonAddon={addonButton ? addonRef.current?.clientWidth : 0}
      labelMinWidth={labelInfo.minWidth}
      size={size}
    >
      <label
        htmlFor={inputId}
        className={`${(labelInfo.isLabelHidden && "visually-hidden") ?? ""}`}
      >
        {labelInfo.label}

        {labelInfo.tooltipInfo && (
          <Tooltip
            title={labelInfo.tooltipInfo.title}
            desc={labelInfo.tooltipInfo.desc}
            width={labelInfo.tooltipInfo.width}
            position={labelInfo.tooltipInfo.position ?? "bottomLeft"}
            disabled={disabled}
          >
            <InfoFilledIcon
              width={16}
              height={16}
              color={disabled ? TEXT_COLOR.black_disabled : TEXT_COLOR.black_3}
            />
          </Tooltip>
        )}
      </label>

      <div className="input-and-error-container">
        <div className="input-container">
          <input
            required={required}
            type={inputType || "text"}
            ref={inputRef}
            id={inputId}
            value={value || ""}
            onChange={(e) => setValidatedValue(e.target.value)}
            inputMode={inputMode}
            disabled={disabled}
            placeholder={placeholder ?? t("sds-v2:InputTextField_입력_안내")}
            onBlur={handleBlur}
            autoComplete={isAutoComplete ? "on" : "one-time-code"}
            readOnly={readOnly}
            onKeyDown={onKeyDown}
          />

          {Addon}
        </div>

        {errorMessage && <div className="error-message">{errorMessage}</div>}
      </div>
    </Styled.container>
  );
}
