import React, { useCallback, useMemo, useState } from "react";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { useResetRecoilState, useSetRecoilState } from "recoil";

import Modal from "@sellernote/shared/src/componentsToMoveToV1/Modal";
import { clearLocalStorageForForwardingShipdaWeb } from "@sellernote/shared/src/services/browserStorage";
import { USER_ATOMS } from "@sellernote/shared/src/states/common/user";
import { FORWARDING_AUTH_ATOMS } from "@sellernote/shared/src/states/forwarding/auth";

export default function useLogout() {
  const { t } = useTranslation(["containers"]);

  const [opensModal, setOpensModal] = useState(false);

  const setLoggedIn = useSetRecoilState(FORWARDING_AUTH_ATOMS.USER_LOGGED_IN);

  const resetUserTeamAuthorityInfo = useResetRecoilState(
    USER_ATOMS.USER_TEAM_AUTHORITY_INFO
  );

  const router = useRouter();

  const handleLogout = useCallback(async () => {
    setOpensModal(false);

    await router.push("/");

    clearLocalStorageForForwardingShipdaWeb();
    window.sessionStorage.clear();

    setLoggedIn(false);
    resetUserTeamAuthorityInfo();

    window.location.reload();
  }, [router, setLoggedIn, resetUserTeamAuthorityInfo]);

  const handleConfirmLogoutModalOpen = () => setOpensModal(true);

  const handleConfirmLogoutModalClose = () => setOpensModal(false);

  const ConfirmLogoutModal = useMemo(
    () => (
      <Modal
        uiType="titleOnly"
        active={opensModal}
        title={t("containers:Layout.정말_로그아웃하시겠습니까_")}
        actionPositive={{
          label: t("containers:Layout.확인"),
          handleClick: handleLogout,
        }}
        actionNegative={{
          label: t("containers:Layout.취소"),
          handleClick: handleConfirmLogoutModalClose,
        }}
      />
    ),
    [handleLogout, opensModal, t]
  );

  return { ConfirmLogoutModal, handleConfirmLogoutModalOpen, handleLogout };
}
