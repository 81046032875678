import styled from "styled-components";

import { COLOR } from "@sellernote/shared/src/stylesToMoveToV1/constants";

const containerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99997;
`;

const container = styled.div`
  display: inline-block;
  position: relative;
  text-align: center;
  cursor: default;
  border-radius: 4px;
  max-height: calc(100% - 16px);
  max-width: calc(100% - 16px);
  z-index: 99999;
  overflow: scroll;
`;

const dimmedBackground = styled.div`
  background-color: ${COLOR.bk_60};
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99998;
`;

const content = styled.div``;

const footer = styled.div`
  display: flex;
  border-radius: 0 0 4px 4px;
  overflow: hidden;
`;

export default {
  containerWrapper,
  dimmedBackground,
  container,
  content,
  footer,
};
