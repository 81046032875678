import styled, { css } from "styled-components";

import { ShipdaCurrentLanguage } from "@sellernote/shared/src/i18n/i18nForShipda";
import { mobile } from "@sellernote/shared/src/styles/functions";
import { COLOR } from "@sellernote/shared/src/sds-v2/styles/colors";
import { setFontStyle } from "@sellernote/shared/src/sds-v2/styles/typography";

const mainMenuContainer = styled.ul`
  display: flex;
  justify-content: flex-start;
  width: 840px;
  gap: 16px;

  ${mobile(css`
    display: none;
  `)}
`;

const mainMenuItem = styled.li<{ width?: string }>`
  width: ${({ width }) => width || "100%"};

  > h3.title {
    ${setFontStyle("SubHead3")};
    color: ${COLOR.grayScale_300};

    > a,
    > a:visited {
      color: ${COLOR.grayScale_300};
    }
  }
`;

const submenuList = styled.ul`
  margin: 8px 0 0 0;

  > li.sub-menu-item {
    &:last-child {
      margin-bottom: 0;
    }

    a,
    a:visited {
      display: block;
      ${setFontStyle("Caption")}
      color: ${COLOR.grayScale_100};
      padding: 8px;
    }
  }
`;

const mobileMainMenuContainer = styled.ul`
  display: none;

  ${mobile(css`
    display: block;
    padding: 16px 8px;

    > li.menu-item h3.title {
      display: flex;
      justify-content: space-between;
      ${ShipdaCurrentLanguage.currentLanguage === "ko"
        ? setFontStyle("SubHead2")
        : setFontStyle("SubHead3", "Point_ENG")};
      color: ${COLOR.grayScale_200};
      padding: 16px;
      cursor: pointer;
      .icon {
        margin-right: 8px;
      }
      a,
      a:visited {
        display: block;
        color: ${COLOR.grayScale_200};
      }
    }
  `)}
`;

const mobileSubmenuList = styled.ul`
  margin: 8px 0 0 0;

  > li.sub-menu-item {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }

    a,
    a:visited {
      display: block;
      ${ShipdaCurrentLanguage.currentLanguage === "ko"
        ? setFontStyle("Body3")
        : setFontStyle("SubHead3", "Point_ENG")}
      color: ${COLOR.grayScale_300};
      margin-bottom: 8px;
      padding: 10px 24px;
    }
  }
`;

export default {
  mainMenuContainer,
  mainMenuItem,
  submenuList,
  mobileMainMenuContainer,
  mobileSubmenuList,
};
