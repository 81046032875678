import React, { useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import Router from "next/router";
import { Trans, useTranslation } from "next-i18next";

import TEAM_QUERY, {
  TEAM_QUERY_KEY_GEN,
} from "@sellernote/shared/src/queries/forwarding/TEAM_QUERY";
import { USER_QUERY_KEY_GEN } from "@sellernote/shared/src/queries/forwarding/USER_QUERY";
import { useCheckIsMobile } from "@sellernote/shared/src/utils/common/hook";
import InputTextField from "@sellernote/shared/src/sds-v2/components/form/InputTextField";
import { Chip } from "@sellernote/shared/src/sds-v2/components/form/InputTextWithChips";

import InvitationTeam from "../../../InvitationTeam";
import BottomSheetForMobile from "./BottomSheetForMobile";
import Complete from "./Complete";
import ModalForDesktop from "./ModalForDesktop";

export interface NewTeamInfo {
  name: string;
  inviteeList: Chip[];
}

export type TitleAndDesc = { title?: React.ReactNode; desc?: React.ReactNode };

export default function CreateTeam({
  opensCreateTeam,
  setOpensCreateTeam,
  closeCreateTeamModal,
}: {
  opensCreateTeam: boolean;
  setOpensCreateTeam: (v: boolean) => void;
  closeCreateTeamModal: () => void;
}) {
  const { t } = useTranslation();

  const { isMobile } = useCheckIsMobile();

  const queryClient = useQueryClient();

  const [step, setStep] = useState(1);

  const [newTeamInfo, setNewTeamInfo] = useState<NewTeamInfo>({
    name: "",
    inviteeList: [],
  });

  const [createdTeamId, setCreatedTeamId] = useState<number>();

  const { mutate: createTeam, ResponseHandler: ResponseHandlerOfCreateTeam } =
    TEAM_QUERY.useCreateTeam({
      onSuccess: (res) => {
        setStep(3);

        setCreatedTeamId(res?.id);
      },
    });

  const {
    mutate: switchAccount,
    ResponseHandler: ResponseHandlerOfSwitchAccount,
  } = TEAM_QUERY.useSwitchAccount({
    onSuccess: () => {
      queryClient.invalidateQueries(TEAM_QUERY_KEY_GEN.getAccountInfo());
      queryClient.invalidateQueries(TEAM_QUERY_KEY_GEN.getTeamMemberList({}));
      queryClient.invalidateQueries(USER_QUERY_KEY_GEN.getUserInfo());

      Router.push("/mypage/company/member");

      closeCreateTeamModal();
    },
  });

  const handleAccountSwitch = () => {
    if (!createdTeamId) return;

    switchAccount({ pathParams: { teamId: createdTeamId } });
  };

  const validatedInviteeEmailList = newTeamInfo.inviteeList
    .filter(({ isError }) => !isError)
    .map(({ value }) => value);

  const duplicatedEmailMergedList = [...new Set(validatedInviteeEmailList)];

  const handleTeamCreate = () => {
    const payload = {
      name: newTeamInfo.name,
      ...(duplicatedEmailMergedList.length
        ? { inviteList: duplicatedEmailMergedList }
        : { inviteList: [] }),
    };

    createTeam(payload);
  };

  const titleAndDesc: TitleAndDesc = (() => {
    if (step === 1)
      return {
        title: t("page-mypage-company-member:팀_생성_1단계_타이틀"),
        desc: t("page-mypage-company-member:팀_생성_1단계_팀명_입력_안내"),
      };

    if (step === 2)
      return {
        title: t("page-mypage-company-member:팀_생성_2단계_타이틀"),
        desc: (
          <Trans
            i18nKey="page-mypage-company-member:팀_생성_2단계_초대_안내"
            values={{ teamName: newTeamInfo.name }}
          />
        ),
      };

    return {};
  })();

  const form = useMemo(() => {
    return step === 1 ? (
      <InputTextField
        labelInfo={{
          label: t("page-mypage-company-member:팀_생성_1단계_입력_팀명"),
          isLabelHidden: true,
        }}
        value={newTeamInfo.name}
        setValue={(v) => setNewTeamInfo((prev) => ({ ...prev, name: v }))}
        placeholder={t("page-mypage-company-member:팀_생성_1단계_입력_문구")}
      />
    ) : step === 2 ? (
      <InvitationTeam
        inviteeList={newTeamInfo.inviteeList}
        setInviteeList={(inviteeList) =>
          setNewTeamInfo((prev) => ({ ...prev, inviteeList: inviteeList }))
        }
      />
    ) : (
      <Complete hasInvitee={!!duplicatedEmailMergedList.length} />
    );
  }, [
    duplicatedEmailMergedList.length,
    newTeamInfo.inviteeList,
    newTeamInfo.name,
    step,
    t,
  ]);

  const moveStep = (step: number) => setStep(step);

  const reset = () => {
    setStep(1);
    setNewTeamInfo({ name: "", inviteeList: [] });
  };

  const close = () => {
    closeCreateTeamModal();
    reset();
  };

  return (
    <>
      {isMobile ? (
        <BottomSheetForMobile
          step={step}
          newTeamInfo={newTeamInfo}
          handleTeamCreate={handleTeamCreate}
          handleAccountSwitch={handleAccountSwitch}
          opensCreateTeam={opensCreateTeam}
          setOpensCreateTeam={setOpensCreateTeam}
          titleAndDesc={titleAndDesc}
          form={form}
          moveStep={moveStep}
          close={close}
        />
      ) : (
        <ModalForDesktop
          step={step}
          newTeamInfo={newTeamInfo}
          handleTeamCreate={handleTeamCreate}
          handleAccountSwitch={handleAccountSwitch}
          opensCreateTeam={opensCreateTeam}
          titleAndDesc={titleAndDesc}
          form={form}
          moveStep={moveStep}
          close={close}
        />
      )}

      {ResponseHandlerOfCreateTeam}
      {ResponseHandlerOfSwitchAccount}
    </>
  );
}
